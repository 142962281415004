/*********************************
*********** + Корзина ************
**********************************/

var cart = new Cart();

function shop () {
    shop.$orderTable    = $('.cart__box');
    shop.$total         = $('.cart .total');
    shop.$subtotal      = $('.cart .subtotal');
    //shop.$orderTotal    = $('#orderTotal');
    shop.$headCart      = $('.head__cart');
    shop.$delivery      = $('.cart .delivery');
    shop.$delivInput    = $('[name = delivery]');
    shop.deliveryPrice  = 0;

    shop.currency = {
        //basic: $('meta[name="currency-basic"]').attr('content'),
        name: $('meta[name="currency-name"]').attr('content'),
    }
    shop.$srcCart       = $('meta[name="cart-url"]').attr('content');

    shop.$pageOptionCart = $('body').hasClass('option-cart') ? 1 : 0;
    //shop.$pageOptionOrder = $('body').hasClass('option-order') ? 1 : 0;

    shop.orderForm = new CheckForms($('#orderForm'), '.button_submit');

    shop.buy();
    shop.request();
    shop.valueTest();
    shop.number();
    shop.delProdInCart();
    //shop.clearAllCart();
    //shop.getDiscount();
    shop.changeDelivery();
    shop.payment();
    shop.steps();
}

shop.number = function () {
    var timeout;

    function setTotal (data, row) {
        shop.$total.text(numberFormat(data.total + ' ' + shop.currency.name, {decimals: 0, thousands_sep: ' '}));
        for (var i in data.cart) {
            if (data.cart[i].id == row.data('id')) {
                var sum = cart.calc(data.cart[i].price, row.find('[name = quantity]').val());
                row.find('.cart__row__sum').text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name)
                break;
            }
        }
        shop.updateCartTable(data);
    }

    shop.$orderTable.on('keyup click', '[name = quantity]', function() {
        clearTimeout(timeout);
        var row = $(this).closest('.cart__row');

        if ($(this).val() == '') $(this).val('1');

        // нашли id изменяемого товара
        var productId = $(this).parents('.cart__row').data('id');

        // ищем в обьекте корзины нужный товар по id
        for (var key in cart.products) {
            if (cart.products[key].id == productId) {
                // обновляем ему кол-во
                cart.products[key].quantity = parseInt($(this).val());
            }
        }

        timeout = setTimeout(function() {
            cart.push(shop.$srcCart, cart.products, function (data) {
                setTotal(data, row);
            });
        }, 300);
    });
}

shop.delProdInCart = function () {
    shop.$orderTable.on('click', '.cart__row__del div', function() {
        var row = $(this).parents('.cart__row');
        var id = row.data('id');
        row.remove();
        cart.delProduct(id);
        if (cart.products.length == 0) location.reload();
        cart.push(shop.$srcCart, cart.products, function (data) {
            //Cart.calcSumTotal();
            shop.$total.text(data.formatTotal + ' ' + shop.currency.name);
        });
    });
}

/*shop.clearAllCart = function () {
    shop.$clearBut.click(function() {
        cart.products = [];
        cart.push(shop.$srcCart, cart.products);
        shop.cartBox.find('.cart-item').each(function() {
            $(this).remove();
        });
        shop.cartBox.find('.button, .all-num, .types-num, .total').hide();
        shop.cartBox.prepend('<div class="empty-cart">Ваша корзина пуста</div>');
    });
}*/

shop.construct = function (data, total) {
    for (var key in data.cart) {
        var product = new Product(data.cart[key]);
        cart.addProduct(product);
    }
    shop.updateCartTable(data);
}

shop.constructBoxCart = function (data) {
    for (var key in data.cart) {
        var product = new Product(data.cart[key]);
        cart.addProduct(product);
        shop.addProdInCart(data);
    }
    //shop.cartBox.find('.total .num').text(data.formatTotal + ' ' + shop.currency.name);
}

shop.request = function () {
    cart.pull(shop.$srcCart, function (data) {
        if (shop.$pageOptionCart) {
            shop.construct(data);
        } else {
            shop.constructBoxCart(data);
        }
    });
}

shop.updateQuantity = function () {
    for (var i = 0; i < cart.products.length; ++i) {
        var item = shop.cartBox.find('[data-id='+cart.products[i].id+']');
        item.find('.quantity').val(cart.products[i].quantity);
    }
}

shop.addProdInCart = function (data) {
    if (!shop.$headCart.find('span').length) shop.$headCart.append('<span></span>');
    shop.$headCart.find('span').text(data.totalQuantity);
    //if (shop.$pageOptionCart) shop.updateQuantity();
}

shop.buy = function () {
    $(document).on('click', '.button_incart', function(event) {
        event.preventDefault();
        var item = $(this);
        var size = item.parent().find('.catalog__item__info__radio').length ? item.parent().find('.catalog__item__info__radio input:checked').val() : '';
        
        var product = new Product({
            id: item.data('id'),
            name: item.data('name'),
            price: item.data('amount'),
            formatPrice: item.data('format-amount'),
            link: item.data('link'),
            size: size,
            src: item.data('src'),
            quantity: item.data('quantity'),
            article: item.data('article'),
        });

        cart.addProduct(product, product.quantity);

        /*if (!shop.cartBox.find('[data-id='+product.id+']').length) {
            shop.addProdInCart(product);
        } else {
            shop.updateQuantity();
        }*/

        cart.push(shop.$srcCart, cart.products, function (data) {
            shop.addProdInCart(data);
            //shop.cartBox.find('.total .num').text(data.formatTotal + ' ' + shop.currency.name);
        });
    });
}

// shop.changeDelivery = function() {
//     shop.$delivInput.change(function () {
//         shop.$delivery.text($(this).data('str'));
//         shop.deliveryPrice = $(this).val();
//         shop.setDelivery();
//     });
// }

shop.setDelivery = function() {
    var request = {
        delivery: shop.$delivInput.val(),
    };
    
    cart.push(shop.$srcCart, cart.products, shop.updateCartTable, request);
}


shop.changeDelivery = function() {
    var address = $('#address');
    //var fieldAdress = $('.forma__field__address');

    shop.$delivInput.each(function() {
        if ($(this).is('input:checked')) shop.$delivery.text($(this).data('str'));
    });

    shop.$delivInput.change(function () {
        shop.$delivery.text($(this).data('str'));
        shop.deliveryPrice = $(this).val();

        if ($(this).attr('id') == 'shop') address.removeClass('requiredField errorField emptyField').parent().hide();
        else address.addClass('requiredField').parent().show();

        shop.setDelivery();
        shop.orderForm.checkEmpty();
    });
}

shop.payment = function() {
    var pay = $('[name = payment]');
    var infopay = $('.cart .payment');
    
    pay.each(function() {
        if ($(this).is('input:checked')) infopay.text($(this).data('str'));
    }).on('change', function() {
        infopay.text($(this).data('str'));
    });

    // pay.on('change', function() {
    //     infopay.text($(this).data('str'));
    // });
}

shop.steps = function() {
    var body = $('body');
    var buttons = $('.cart__columns').find('.button_back, .button_next');
    var caption = $('h1');

    buttons.on('click', function(e) {
        if ($(this).hasClass('button_next')) {
            body.addClass('option-order');
            caption.text('Оформление заказа');
        } else {
            body.removeClass('option-order');
            caption.text('Корзина');
        }
        e.preventDefault();
    });
}

shop.updateCartTable = function(data) {
    /*if (typeof data.formatDelivery != 'undefined') {
        shop.$delivery.text(data.formatDelivery + ' ' + shop.currency.name);
    } else {
        shop.$delivery.text(shop.$deliveryEmpty.val());
    }*/
    shop.$subtotal.text(numberFormat(data.total, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    shop.$total.text(numberFormat(data.total, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    if (shop.$pageOptionCart) {
        var sum = Number(data.total) + Number(shop.deliveryPrice);
        shop.$total.text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    }
}

shop.valueTest = function () {
    shop.$orderTable.on('keypress', '[name = quantity]', function(e) {
        e = e || Event;

        if (e.ctrlKey || e.altKey || e.metaKey) return;

        var chr = shop.getChar(e);

        if (chr == null) return;

        if (chr < '1' || chr > '9') {
            return false;
        }
    });
}

shop.getChar = function(event) {
    if (event.which == null) {
        if (event.keyCode < 32) return null;
        return String.fromCharCode(event.keyCode) // IE
    }

    if (event.which != 0 && event.charCode != 0) {
        if (event.which < 32) return null;
        return String.fromCharCode(event.which) // остальные
    }

    return null; // специальная клавиша
}

function numberFormat (number, cfg) {
	function obj_merge(obj_first, obj_second){
		var obj_return = {};
		for (key in obj_first){
			if (typeof obj_second[key] !== 'undefined') obj_return[key] = obj_second[key];
			else obj_return[key] = obj_first[key];
		}
		return obj_return;
	}
	function thousands_sep(num, sep){
		if (num.length <= 3) return num;
		var count = num.length; var num_parser = ''; var count_digits = 0;
		for (var p = (count - 1); p >= 0; p--){
			var num_digit = num.substr(p, 1);
			if (count_digits % 3 == 0 && count_digits != 0 && !isNaN(parseFloat(num_digit))) num_parser = sep + num_parser;
			num_parser = num_digit + num_parser;
			count_digits++;
		}
		return num_parser;
	}
	if (typeof number !== 'number'){
		number = parseFloat(number);
		if (isNaN(number)) return false;
	}
	var cfg_default = {before: '', after: '', decimals: 2, dec_point: '.', thousands_sep: ','};
	if (cfg && typeof cfg === 'object'){ cfg = obj_merge(cfg_default, cfg); } else cfg = cfg_default;
	number = number.toFixed(cfg.decimals);
	if (number.indexOf('.') != -1){
		var number_arr = number.split('.');
		var number = thousands_sep(number_arr[0], cfg.thousands_sep) + cfg.dec_point + number_arr[1];
	} else var number = thousands_sep(number, cfg.thousands_sep);
	return cfg.before + number + cfg.after;
}